<template>
  <div>
    <AppWrapper :lists="lists" :drawer="drawer" :tasks="tasks">
      <template v-slot:app-sidebar-header-content>
        <v-btn color="info" block @click="dialog = true"> + NEW Task</v-btn>
      </template>
      <template v-slot:app-sidebar-body-content>
        <v-list flat v-for="(list, idx) in lists" :key="idx">
          <v-subheader v-if="list.subheader">{{ list.subheader }}</v-subheader>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item class="px-10" v-for="(item, i) in list.items" :key="i">
              <v-list-item-icon>
                <v-icon
                  :size="item.size"
                  :color="item.color"
                  v-text="item.icon"
                ></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
      <template v-slot:app-header-content>
        <v-text-field
          label="Outlined"
          placeholder="Placeholder"
          outlined
          prepend-inner-icon="mdi-magnify"
          class="ma-0 pa-0"
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:app-header-content-append>
        <!-- MENU with VERTICAL DOTS -->
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-for="(item, i) in headerMenu" :key="i">
              <v-list-item-icon>
                <v-icon :color="item.color" v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:app-body-content>
        <v-list-item
          v-for="task in tasks"
          :key="task.id"
          class="d-flex justify-space-between flex-wrap align-center mb-5 white pa-4 rounded"
        >
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content class="ma-0">
              <v-row no-gutters>
                <span class="text-h6">{{ task.title }}</span>
                <v-spacer></v-spacer>
                <v-chip v-for="label in task.labels" :key="label" class="mr-2">
                  {{ label }}
                </v-chip>

                <v-avatar v-if="task.user" class="ml-2" size="36">
                  <img
                    v-if="task.user.avatar"
                    :src="task.user.avatar"
                    :alt="task.user.username"
                  />
                </v-avatar>
                <v-btn icon>
                  <v-icon>
                    mdi-star
                  </v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </v-row>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
    </AppWrapper>
    <base-dialog
      v-model="dialog"
      :title="$t('title')"
      max-width="600"
      icon="mdi-information-outline"
    >
      <template v-slot:content>
        <v-container>
          test
        </v-container>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import AppWrapper from '@/components/wrappers/AppWrapper.vue';
export default {
  name: 'TodoAppView',
  components: {
    AppWrapper
  },
  data: () => ({
    drawer: false,
    selectedItem: 1,
    settings: null,
    dialog: false,
    lists: [
      {
        subheader: 'Filters',
        id: 'filters',
        items: [
          { text: 'Favorite', icon: 'mdi-star-outline' },
          { text: 'Important', icon: 'mdi-alert-decagram-outline' },
          { text: 'Completed', icon: 'mdi-account-check' }
        ]
      },

      {
        id: 'labels',
        subheader: 'Labels',
        items: [
          {
            text: 'Frontend',
            icon: 'mdi-checkbox-blank-circle-outline',
            size: 'medium',
            color: 'blue'
          },
          {
            text: 'Backend',
            icon: 'mdi-checkbox-blank-circle-outline',
            size: 'medium',
            color: 'orange'
          },
          {
            text: 'Doc',
            icon: 'mdi-checkbox-blank-circle-outline',
            size: 'medium',
            color: 'green'
          },
          {
            text: 'Bug',
            icon: 'mdi-checkbox-blank-circle-outline',
            size: 'medium',
            color: 'red'
          }
        ]
      },

      {
        subheader: 'Storage',
        id: 'storage',
        items: [
          { text: 'Real-Time', icon: 'mdi-clock' },
          { text: 'Audience', icon: 'mdi-account' },
          { text: 'Conversions', icon: 'mdi-flag' }
        ]
      }
    ],
    tasks: [
      {
        id: '1',
        title: 'Refactor Code',
        labels: ['doc', 'backend'],
        user: {
          avatar: 'https://cdn.vuetifyjs.com/images/john.jpg',
          username: 'John Doe'
        }
      },
      {
        id: '2',
        title: 'Submit Report',
        labels: ['frontend', 'doc'],
        user: {
          avatar: 'https://cdn.vuetifyjs.com/images/john.jpg',
          username: 'Lea Marie'
        }
      },
      {
        id: '3',
        title: 'Fix Bug',
        labels: ['frontend', 'doc', 'bug'],
        user: {
          avatar: 'https://cdn.vuetifyjs.com/images/john.jpg',
          username: 'Lea Marie'
        }
      }
    ],
    headerMenu: [
      { title: 'Account', icon: 'mdi-account-check', color: 'primary--text' },
      { title: 'Delete', icon: 'mdi-trash-can', color: 'primary--text' },
      { title: 'Click Me', icon: 'mdi-account-check', color: 'primary--text' }
    ]
  })
};
</script>

<style lang="sass"></style>
